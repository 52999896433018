<template>
	<Page name="reformeur" 
	title="Le réformeur régénératif"
	implementation="1994"
	tonnesHandled="110"
	processingTemperature="500"
	abstract="<p>Cette unité permet d’augmenter l’indice d’octane des essences issues de la Distillation, du Craqueur, du Viscoréducteur et de produire de l’hydrogène.</p>"
	animation="static/animation/REFORMATEUR.json">
	<picture slot="thumbnail">
		<img class="lazy"
		sizes="(max-width: 480px) 100vw, 480px"
		data-srcset="
		img/secteur/RR_mjt2gu_c_scale_w_350.jpg 350w,
		img/secteur/RR_mjt2gu_c_scale_w_480.jpg 480w"
		data-src="img/secteur/RR_mjt2gu_c_scale_w_480.jpg"
		src="static/logo-total.jpg"
		alt="">
	</picture>
</Page>
</template>

<script>
	export default {
		name: 'reformeur',
		components: {
			Page: () => import('@/components/Page.vue'),
		},
	}
</script>
